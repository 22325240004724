import axios from '@axios';

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchSupervisors(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/supervisors', { params: queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        addSupervisor(ctx, { payload }) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/supervisors/create', payload)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        updateSupervisor(ctx, { id, payload }) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/supervisors/${id}/update`, payload)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        deleteSupervisor(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/supervisors/${id}/delete`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        }
    }
}